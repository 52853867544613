import React, { useContext, useEffect, useState } from 'react';
import { Field, LinkFieldValue, RichText } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import Checkbox from '@material-ui/core/Checkbox';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { LinkRenderer } from '../LinkRenderer';
import CustomTooltip from '../CustomTooltip';

import { orderSummaryCheckoutDetails } from './IOrderSummaryCheckout';
import { getAPIAsyncCall, getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { OrderSummaryDetails } from '../OrderSummary';
import { getCurrencyInUSD } from '../../utils/helper';
import PlaceOrderProcess from '../PlaceOrderProcess';
import LoadingSkeleton from '../LoadingSkeleton';
import { APIWebstoreContext } from '../../context/globalContext';
import { ITaxExemptStatusDetails } from '../AccountInfo/TaxExempt';
import TaxExemptStatus from '../TaxExemptStatus';
import ToastNotification from '../ToastNotification';
import { getCountryConfigFields } from '../../utils/countryConfigurationHelper';
import { ProcessOrderValidationService } from '../CheckoutPage/ProcessOrderValidationService';

export type ReviewCartOrderProps = {
  fields: {
    Link: Field<LinkFieldValue>;
    TermsAgreementMessage: Field<string>;
    TermsAgreementTooltipText: Field<string>;
    MemberDiscountTooltipText: Field<string>;
    MemberDiscountTitle: Field<string>;
    TaxExemptTooltipText: Field<string>;
    DisclaimerText: Field<string>;
    ToastMessage: Field<string>;
    ToastMessagePayFabric: Field<string>;
    Countries: Array<OrderSummaryFieldProps>;
    RestrictedCountries: Array<CountryFieldProps>;
    Language: Field<string>;
  };
};

export type CountryFieldProps = {
  displayName: string;
  fields: ICountryFields;
};

export interface ICountryFields {
  CountryName: Field<string>;
  CountryCode: Field<string>;
}

export type OrderSummaryFieldProps = {
  displayName: string;
  fields: IOrderSummaryFields;
};

export interface IOrderSummaryFields {
  CountryCode: Field<string>;
  CountryName: Field<string>;
  HideShippingAndHandling: Field<boolean>;
  HideTaxExempt: Field<boolean>;
  HideMemberDiscount: Field<boolean>;
  HideSalesTax: Field<boolean>;
  SalesTaxMessage: Field<string>;
  OrderSummaryBtnText: Field<string>;
  Language: Field<string>;
}

const OrderSummaryCheckout = ({ fields }: ReviewCartOrderProps): JSX.Element => {
  const [IsUSOnly] = useState(true);
  const [orderSummaryDetails, setOrderSummaryDetails] = useState<OrderSummaryDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [isTaxLoading, setIsTaxLoading] = useState(true);
  const { state, dispatch } = useContext(APIWebstoreContext);
  const [isEULA, setIsEULA] = useState(false);
  const [taxExemptStatusDetails, setTaxExemptStatusDetails] = useState<ITaxExemptStatusDetails>();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<Field<string>>();
  const [customToastMessage, setCustomToastMessage] = useState(fields.ToastMessage);
  const [countryInfo, setCountryInfo] = useState('');

  const fetchData = () => {
    getAPICall(`${process.env.REACT_APP_CART_URL}/checkout/ordersummary`)
      .then(function (response: any) {
        if (response && response.data) {
          setOrderSummaryDetails(response.data);
          if (dispatch) {
            dispatch({
              type: 'CHECKOUT_UPDATE',
              payload: {
                orderTotal: response.data?.total,
              },
            });
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
        setIsTaxLoading(false);
      });
  };

  async function getShippingCountry() {
    let shippingCountry = null;
    try {
      const response = await getAPIAsyncCall(
        `${process.env.REACT_APP_CART_URL}/checkout/getshippingcountry`
      );
      if (response && response.data) {
        shippingCountry = response.data;
      }
    } catch (error: any) {
      shippingCountry = null;
    } finally {
      if (!shippingCountry) {
        shippingCountry = ProcessOrderValidationService.getShippingInfo()?.shippingInfo?.country;
      }
      setCountryInfo(shippingCountry);
    }
    return shippingCountry;
  }

  function getTaxExemptStatusApiCall() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/taxexemptstatus`).then(function (
      response: any
    ) {
      if (response && response.data) {
        setTaxExemptStatusDetails(response.data);
        if (response.data.IsTaxExemptPending) {
          showCustomToast(
            state?.sitecoreDictionaryData
              ? state?.sitecoreDictionaryData.TaxExemptPendingMessage
              : 'Your checkout is disabled until tax exempt verification is completed. It takes us about 2-3 days to get the verification completed.'
          );
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, [state?.toggleOrderSummaryLoad]);

  useEffect(() => {
    setIsTaxLoading(true);
    const asycService = async () => {
      await getShippingCountry();
      fetchData();
      getTaxExemptStatusApiCall();
    };
    asycService();
  }, [state?.toggleCalculateOrder]);

  const toastRemove = () => {
    setShowToast(false);
  };

  const showCustomToast = (message: string) => {
    customToastMessage.value = message;
    setToastMessage(customToastMessage);
    setShowToast(true);
  };

  function renderWithLoading(value: any, theme?: any) {
    return isLoading ? <LoadingSkeleton theme={theme} /> : value;
  }

  function renderWithTaxLoading(value: any, theme?: any) {
    return isTaxLoading ? <LoadingSkeleton theme={theme} /> : value;
  }

  function getSalesTax(
    IsUSOnly: boolean,
    countryConfigurationInfo: IOrderSummaryFields | undefined
  ) {
    if (!IsUSOnly) {
      return (
        <div className="orderSalesTax orderSummaryAlign">
          <p className="orderSalesTaxDetail">VAT/GST:</p>
          <p className="orderSalesTaxDetail">
            {renderWithLoading(orderSummaryCheckoutDetails.orderSalesTax)}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={
            fields.RestrictedCountries.some((x) => x.fields.CountryCode.value === countryInfo) ||
            (!taxExemptStatusDetails?.IsTaxExempt && !taxExemptStatusDetails?.IsTaxExemptPending)
              ? 'salesTax'
              : 'salesTaxPending'
          }
        >
          {!countryConfigurationInfo?.HideSalesTax.value && (
            <div className="orderSalesTaxNotUS orderSummaryAlign">
              <p className="orderSalesTaxDetailNotUS">Sales Tax:</p>
              {(countryConfigurationInfo?.HideTaxExempt.value ||
                (!taxExemptStatusDetails?.IsTaxExempt &&
                  !taxExemptStatusDetails?.IsTaxExemptPending)) && (
                <p className="orderSalesTaxDetailNotUS">
                  {countryConfigurationInfo?.SalesTaxMessage.value &&
                  countryConfigurationInfo?.SalesTaxMessage.value.length > 0
                    ? countryConfigurationInfo?.SalesTaxMessage.value
                    : renderWithTaxLoading(getCurrencyInUSD(orderSummaryDetails?.taxTotal))}
                </p>
              )}
            </div>
          )}
          {!countryConfigurationInfo?.HideTaxExempt.value && (
            <div className="orderSummaryAlign taxExemptBorder">
              <TaxExemptStatus
                fields={{
                  TaxExemptTooltip: fields.TaxExemptTooltipText,
                }}
                props={{
                  TaxExemptStatusDetails: taxExemptStatusDetails,
                  hideTaxExempt: countryConfigurationInfo?.HideTaxExempt.value,
                }}
              ></TaxExemptStatus>
            </div>
          )}
        </div>
      );
    }
  }

  function getCountryConfigurationData(shippingCountry: string) {
    let countryConfigurationData: Array<OrderSummaryFieldProps> = [];

    countryConfigurationData = getCountryConfigFields(shippingCountry, fields.Countries);
    const countryConfigurationFields: IOrderSummaryFields | undefined =
      countryConfigurationData.map((config) => {
        return {
          CountryCode: config.fields.CountryCode,
          CountryName: config.fields.CountryName,
          HideShippingAndHandling: config.fields.HideShippingAndHandling,
          HideTaxExempt: config.fields.HideTaxExempt,
          HideMemberDiscount: config.fields.HideMemberDiscount,
          HideSalesTax: config.fields.HideSalesTax,
          SalesTaxMessage: config.fields.SalesTaxMessage,
          OrderSummaryBtnText: config.fields.OrderSummaryBtnText,
          Language: config.fields.Language,
        };
      })[0];
    return countryConfigurationFields;
  }

  function getOrderSummary() {
    var countryConfigurationInfo = getCountryConfigurationData(countryInfo);
    return (
      <div className="orderSummaryCheckout">
        <h1>Order Summary</h1>
        <div className="orderSummaryEditCart">
          <AiOutlineShoppingCart></AiOutlineShoppingCart>
          <LinkRenderer
            fields={{
              Link: fields.Link,
            }}
          />
        </div>
        <div className="orderPriceDetails">
          <div className="orderSubTotal orderSummaryAlign">
            <p className="orderSubTotalDetail">Product Subtotal:</p>
            <p className="orderSubTotalDetail">
              {renderWithLoading(getCurrencyInUSD(orderSummaryDetails?.subTotal))}
            </p>
          </div>
          {!countryConfigurationInfo?.HideMemberDiscount.value && (
            <div className="orderMemberDiscount orderSummaryAlign">
              <div className="orderMemberDiscountTitleSection">
                <p>{fields.MemberDiscountTitle.value}</p>
                <CustomTooltip
                  fields={{
                    'Tooltip Text': fields.MemberDiscountTooltipText,
                  }}
                />
              </div>
              <p className="member-discount-value">
                {renderWithLoading(
                  orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.memberDiscount),
                  'light'
                )}
              </p>
            </div>
          )}

          {!countryConfigurationInfo?.HideShippingAndHandling.value && (
            <div className="orderShippingAndHandling orderSummaryAlign">
              <p className="orderShippingDetail orderSummaryAlign">Shipping and Handling:</p>
              <p className="orderShippingDetail">
                {renderWithLoading(
                  orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.shippingTotal)
                )}
              </p>
            </div>
          )}

          {getSalesTax(IsUSOnly, countryConfigurationInfo)}
          <div className="orderTotal">
            <div className="orderTotalSection">
              <p>Total:</p>
              <p>
                {renderWithLoading(
                  orderSummaryDetails && getCurrencyInUSD(orderSummaryDetails?.total)
                )}
              </p>
            </div>
          </div>
          <div className="orderAgreementSection">
            <div className="orderAgreementSectionTitleSection">
              <Checkbox
                color="default"
                onChange={(e) => setIsEULA(e.target.checked)}
                title={fields.TermsAgreementTooltipText.value}
              />
            </div>
            <>
              <RichText className="orderAgreementText" field={fields.TermsAgreementMessage} />
            </>
          </div>
        </div>
        <PlaceOrderProcess
          fields={{
            ToastMessage: fields.ToastMessage,
            ToastMessagePayFabric: fields.ToastMessagePayFabric,
          }}
          orderId={orderSummaryDetails?.orderId || ''}
          orderTotal={orderSummaryDetails?.total || 0}
          taxTotal={orderSummaryDetails?.taxTotal || 0}
          shippingTotal={orderSummaryDetails?.shippingTotal || 0}
          isEULA={isEULA}
          taxExemptPending={
            taxExemptStatusDetails?.IsTaxExemptPending !== undefined
              ? taxExemptStatusDetails.IsTaxExemptPending
              : false
          }
          isOrderLoading={isLoading}
          orderSummaryBtnText={countryConfigurationInfo?.OrderSummaryBtnText.value}
          language={countryConfigurationInfo?.Language.value}
          restrictedCountries={fields?.RestrictedCountries}
        ></PlaceOrderProcess>
        <div className="checkoutDisclaimerSection">
          <>
            <RichText field={fields.DisclaimerText} />
          </>
        </div>
        <ToastNotification
          fields={{
            ToastMessage: toastMessage,
          }}
          showToast={showToast}
          removeToast={toastRemove}
          toastStatus="error"
        ></ToastNotification>
      </div>
    );
  }

  return <div className="orderSummarySticky">{getOrderSummary()}</div>;
};

export default OrderSummaryCheckout;
